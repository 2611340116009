import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import Listing from '../components/listing'
import InfoBox from '../components/info-box'
import StarRating from '../components/star-rating'
import SubscribeCta from '../components/subscribe-cta'

const RecipesListTemplate = ({ data }) => {
  const recipes = data.allMarkdownRemark.nodes

  const metaDesc =
    `What are the best s’mores recipes? I test them out and let you know how` +
    `they turn out.`

  const socialImage = 'https://www.smorescout.com/images/social/recipes-summary.png'
  const socialImageAlt = `S’mores recipe ratings on a scale of 1 to 5`
  const canonicalUrl = `${data.site.siteMetadata.siteUrl}/recipes/`

  return (
    <Layout>
      <Seo
        title="S’mores Recipe Ideas"
        description={metaDesc}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Section className="pb-5">
        <Container>
          <Row className="justify-content-around">
            <Col span={12} lg={9} xl={12} className="mb-2">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>
                S’mores Recipes
              </h1>
            </Col>
          </Row>

          <Row className="justify-content-around">
            <Col xs={12} lg={9} xl={8}>
              <p className="lead mb-5">
                What are the best s’mores recipes? I scour the Internet,
                cookbooks, and ingredient packaging for the best s’mores recipe
                ideas. Let's give them a try.
              </p>

              {recipes.map(recipe => {
                return (
                  <div key={recipe.fields.slug} className="mb-4">
                    <Listing
                      slug={recipe.fields.slug}
                      title={recipe.frontmatter.title}
                      rating={recipe.frontmatter.rating.overall}
                      summary={recipe.frontmatter.summary}
                      image={recipe.frontmatter.images[0]}
                      theme="sunny"
                    />
                  </div>
                )
              })}
            </Col>

            <Col as="aside" xs={12} lg={9} xl={4}>
              <InfoBox>
                <InfoBox.Body>
                  <h2 className="h4 mb-3">
                    Hey, we're just getting started!
                  </h2>

                  <div className="mb-3">
                    <StarRating rating={2} size="large" theme="dark" />
                  </div>
                  <p>
                    Hang tight, s’more s’mores recipes are coming.
                  </p>
                  <p>
                    Have an idea? Email me at
                    {' '}
                    <strong>
                      <a href="mailto:chris@smorescout.com">
                        tips@smorescout.com
                      </a>
                    </strong>
                  </p>
                </InfoBox.Body>
              </InfoBox>
            </Col>
          </Row>
        </Container>
      </Section>

      <SubscribeCta>
      <h2 className="mb-3">
        Get s’more recipes in your inbox
      </h2>
      </SubscribeCta>
    </Layout>
  )
}

export const query = graphql`
  query Recipes {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      filter: { fields: { content_type: { eq: "recipes" }}}
      sort: {fields: frontmatter___published_on, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          summary
          rating {
            overall
          }
          images: image_gallery {
            thumb: file {
              childImageSharp {
                gatsbyImageData(width: 247, height: 247, transformOptions: {
                  cropFocus: CENTER
                })
              }
            }
            alt
          }
        }
      }
    }
  }
`

export default RecipesListTemplate
